/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';
/* @import url('https://unpkg.com/bootstrap@3.3.7/dist/css/bootstrap.min.css'); */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

.mat-button-focus-overlay {
  background-color: transparent !important;
}
button:focus {
  outline: none;
}
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Helvetica Neue, sans-serif;
}

h2 {
  font-size: 16px;
  font-weight: bold;
  color: rgb(45, 80, 105);
}

h3 {
  font-size: 16px;
  font-weight: bold;
  color: rgb(13, 52, 80);
}

h4 {
  font-size: 14px;
  font-weight: bold;
  color: rgb(13, 52, 80);
}

p,
li {
  font-size: 14px;
}

a,
label {
  font-size: 15px;
}


/************************ Botón con Spinner **************************/
@keyframes spinnerKeyFrame {
  to {
    transform: rotate(360deg);
  }
}

.spinnerButton::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinnerKeyFrame 0.8s linear infinite;
}